:root {
  --main-bg-color: #282c34;
  --table-even-row: hsl(232, 33%, 14%);
  --table-odd-row: hsl(231, 29%, 27%);
}

* {
  box-sizing: border-box;
}

/* https://www.fontsquirrel.com/fonts/barlow?utm_source=fontsquirrel.com&utm_medium=matcherator_link&utm_campaign=barlow */
@font-face {
  font-family: "barlow";
  src: url("./fonts/barlow/barlow_regular_macroman/Barlow-Regular-webfont.woff")
    format("woff");
}

@font-face {
  font-family: "barlow-bold-italic";
  src: url("./fonts/barlow/barlow_bolditalic_macroman/Barlow-BoldItalic-webfont.woff")
    format("woff");
}

body {
  margin: 0;
  font-family: "barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
